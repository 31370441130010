.App {
  background-color: var(--main-background);
}

.disclaimer {
  font-size: 12px;
  margin-bottom: 15px;
  z-index: 20;
}

.logo {
  position: absolute;
  left: 50px;
  top: 50px;
}

.logo:hover {
  cursor: pointer;
}

.bold {
  font-weight: 600;
}

.title {
  z-index: 200;
}

.no-scroll {
  height: 100vh;
  overflow: hidden;
}

.opaque {
  color: var(--main-accent);
  letter-spacing: 5px;
}

.opaque-text {
  color: rgba(255, 255, 255, 0.5);
}

.flex {
  display: flex;
}

.button-primary {
  background: var(--main-accent-gradient);
  text-shadow: black 1px 1px 10px;
  color: var(--font-main-white);
  font-weight: bold;
}

.button-secondary {
  border: 1px solid var(--main-2-trans);
  color: var(--main-3-trans);
  background-color: var(--main-2-trans);
}

.paragraph {
  margin: 20px 0;
  max-width: 600px;
  color: var(--main-3-trans);
  line-height: 30px;
  z-index: 20;
}

.paragraph-important {
  margin: 20px 0;
  max-width: 600px;
  color: var(--main-3-trans);
  line-height: 30px;
  z-index: 20;
  font-size: 20px;
  font-weight: bold;
}

.toast-element {
  position: absolute;
  right: 25px;
  top: 80px;
  background-color: var(--main-2-trans);
  border: 1px solid var(--main-accent);
  padding: 10px;
  border-radius: 8px;
  height: fit-content;
  width: fit-content;
}
