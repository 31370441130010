.artists {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.artists-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.site-title {
  font-weight: 600;
  width: 100vw;
  text-align: center;
}
