html {
  /*dark theme main colors*/
  --main-1: #0c142a;
  --main-2: #ffffff;
  --main-3: #0c142a;
  --main-accent: #d300ff;

  --main-accent-pride: linear-gradient(
    90deg,
    #fe0000 16.66%,
    #fd8c00 16.66%,
    33.32%,
    #ffe500 33.32%,
    49.98%,
    #119f0b 49.98%,
    66.64%,
    #0644b3 66.64%,
    83.3%,
    #c22edc 83.3%
  );

  --main-accent-gradient: linear-gradient(-35deg, #d300ff, #8300ff);
  --main-accent-gradient-analyse: linear-gradient(-35deg, #d300ff 0px, #8300ff 400px);
  --main-background: #0b001b;
  --main-background-persistent: linear-gradient(
    -20deg,
    rgba(15, 226, 103, 1),
    rgba(180, 16, 206, 1),
    rgba(249, 149, 18, 1)
  );

  --background-footer: linear-gradient(180deg, #0b001b, #4f006e);

  /* transparent colors */
  --main-1-trans: #0c142a88;
  --main-2-trans: #ffffff22;
  --main-3-trans: #ffffffbb;
  --main-accent-trans: #d300ff77;

  /*font colors*/
  --font-main: #ffffff;
  --font-main-secondary: #0c142a;
  --font-main-white: #ffffff;
  --font-main-dark: #0c142a;

  /* others */
  --spotify_main: #1db954;
  --main-red: #dd1122;
}

html[dark] {
  /*dark theme main colors*/
  --main-1: #ffffff;
  --main-2: #0c142a;
  --main-3: ;
  --main-4: ;
  --main-5: ;

  /*font colors*/
  --font-main: #1e3264;
  --font-main-white: #ffffff;
  --font-main-dark: #0c142a;

  /* others */
  --spotify_main: #1db954;
}
