.genre {
  width: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px;
  background-color: var(--main-2-trans);
  border-radius: 5px;
  height: 80px;
}

.genre .left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  background-color: var(--main-2);
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.genre .left h3 {
  color: var(--font-main-secondary);
}

.genre .right {
  width: 270px;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.genre .right p {
  font-size: 18px;
}

@media only screen and (max-width: 1185px) {
  .genre {
    margin: 25px 25px 0 25px;
  }
}
