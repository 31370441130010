* {
  color: var(--font-main);
}

h1 {
  font-size: 38px;
  font-weight: 600;
  line-height: 45px;
  margin-bottom: 30px;
  width: fit-content;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 32px;
  font-weight: 600;
}

h4 {
  font-size: 24px;
}

p {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 5px;
}
