.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 30px;
}

.header-link {
  text-decoration: none;
}
