.artist {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  margin: 25px;
  position: relative;
  box-sizing: border-box;
  background: var(--main-2-trans);
  border-radius: 20px;
}

.artist-card-image {
  position: relative;
  width: 350px;
  height: 325px;
  background-size: cover;
  border-radius: 15px 15px 0 0;
  filter: grayscale(1);
}

.artist-card-genres {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  bottom: 0;
  box-sizing: border-box;
  padding: 10px;
}

.artist-card-genres-background {
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, #000000ff, #00000033, #00000000);
}

.artist-card-genre-tag {
  height: fit-content;
  width: fit-content;
  padding: 2px 6px;
  border: 1px solid white;
  background-color: #ffffff55;
  border-radius: 20px;
  margin-top: 10px;
}

.artist-card-genre-tag p {
  width: fit-content;
  height: fit-content;
  margin: 0;
  font-size: 10px;
}

.artist-rank {
  position: absolute;
  top: calc(50% - 10px);
  font-size: 100px;
  font-weight: 400;
  opacity: 0;
  z-index: 3;
  -webkit-transition: -webkit-opacity 0.3s;
  transition: -webkit-opacity 0.3s;
  transition: opacity 0.3s;
  transition: opacity 0.3s, -webkit-opacity 0.3s;
}

.artist-about {
  text-decoration: none;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 15px;
  margin: 10px;
  font-weight: 400;
  opacity: 0;
  z-index: 3;
  -webkit-transition: -webkit-opacity 0.3s;
  transition: -webkit-opacity 0.3s;
  transition: opacity 0.3s;
  transition: opacity 0.3s, -webkit-opacity 0.3s;
}

.artist-about-arrow {
  width: 15px;
}

.img-container {
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
}

.img-container::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #000;
  border-radius: 15px 15px 0 0;
  -webkit-transition: -webkit-opacity 0.3s;
  transition: -webkit-opacity 0.3s;
  transition: opacity 0.3s;
  transition: opacity 0.3s, -webkit-opacity 0.3s;
  opacity: 0;
}

.img-container:hover::after {
  opacity: 0.6;
}

.img-container:hover .artist-rank {
  opacity: 0.8;
}

.img-container:hover .artist-about {
  opacity: 0.8;
}

.artist-card-name {
  font-size: 18px;
  margin-bottom: 25px;
  text-align: center;
}

@media only screen and (max-width: 1185px) {
  .artist {
    margin: 25px 25px 0 25px;
  }
}
