.genres {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.genres-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.include-ranking-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 20px 20px 20px;
}

.ranking-container {
  align-items: center;
}
.ranking-container p {
  margin: 0;
}

.artists-weighting {
  margin-left: 10px;
  transition: background-color 0.2s ease;
  height: 30px;
  width: 60px;
  border-radius: 15px;
  background-color: var(--main-2-trans);
  box-sizing: border-box;
  padding: 5px;
}

.artists-weighting-switch {
  left: 0px;
  transition: left 0.2s ease;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  position: relative;
  background-color: var(--main-2);
}

.info-card {
  width: 300px;
  height: fit-content;
  background-color: var(--main-2);
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  margin: 10px;
}

.info-card p {
  color: var(--font-main-dark);
  font-weight: bold;
}
