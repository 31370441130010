.overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
}
.overview-title {
  width: 80%;
  text-align: center;
}

@media only screen and (max-width: 1000px) {
  .overview {
    text-align: center;
  }
}
