html {
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Poppins', 'Roboto', 'Oxygen', 'Ubuntu', -apple-system, BlinkMacSystemFont,
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  min-height: 100vh;
  overflow-x: hidden;
}

button {
  height: 50px;
  width: fit-content;
  padding: 10px 20px;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  margin: 20px 20px 0 0;
  z-index: 20;
  transition: all 0.5s;
}

button:disabled {
  cursor: not-allowed;
  filter: grayscale(1);
}

input {
  height: 50px;
  max-width: 500px;
  border-radius: 8px;
  border: 1px solid var(--main-2-trans);
  color: white;
  background-color: var(--main-2-trans);
  margin: 10px 0 30px 0;
  padding: 20px;
  box-sizing: border-box;
}

textarea {
  max-width: 500px;
  border-radius: 8px;
  border: 1px solid var(--main-2-trans);
  color: white;
  background-color: var(--main-2-trans);
  margin: 10px 0 30px 0;
  padding: 12px 20px;
  min-height: 100px;
  box-sizing: border-box;
  font-family: 'Poppins', 'Roboto', 'Oxygen', 'Ubuntu', -apple-system, BlinkMacSystemFont,
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

/* button:hover {
  box-shadow: #8e8d8d 2px 2px 8px 0px;
} */

img {
  height: 100%;
  width: auto;
}

p {
  line-height: 20px;
  letter-spacing: 0.5px;
}
