.navigation {
  display: flex;
  width: 50%;
  justify-content: space-around;
  align-items: center;
}

.navigation-item {
  text-decoration: none;
  width: fit-content;
  -webkit-transition: -webkit-font-size 0.3s;
  transition: -webkit-font-size 0.3s;
  transition: font-size 0.3s;
  transition: font-size 0.3s, -webkit-font-size 0.3s;
}

.navigation-inactive {
  position: relative;
}

.navigation-inactive:hover {
  font-size: 24px;
}

.navigation-active {
  font-weight: 600;
  font-size: 32px;
}
