.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  background: var(--background-footer);
}

.creators {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
}

.disclaimer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.separator {
  margin: 25px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.8);
  width: 300px;
}

.separator-short {
  margin: 5px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  width: 100px;
}

.footer-link {
  text-decoration: none;
}

.creator-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 30px 30px 30px;
}

.creator-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.creator-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  padding: 5px;
  margin-bottom: 5px;
}

.creator-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.creator-link-label {
  margin: 0;
  padding: 0;
}

.creator-image {
  height: 120px;
  margin-bottom: 10px;
  border-radius: 100%;
}

.creator-link-image {
  height: 30px;
  margin-right: 10px;
}
