.about {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 120px 50px 50px 50px;
  box-sizing: border-box;
  overflow: hidden;
}

.purple-outline {
  -webkit-text-stroke: 1px var(--main-accent);
}

@media only screen and (max-width: 600px) {
  .about {
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 120px 50px 50px 50px;
    box-sizing: border-box;
  }
}
