.landingpage {
  display: flex;
  flex-direction: row;
  background-size: cover;
  /* filter: blur(1px); */
  background-color: var(--main-background);
  overflow: hidden;
}

.landingpage-image {
  filter: blur(1px) opacity(0.5);
  height: 100vh;
  width: 100vw;
  background-size: cover;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}

.color-circle {
  background: var(--main-accent-gradient);
  box-shadow: 1px 4px 30px 0px var(--main-accent-trans);
  height: 500px;
  width: 500px;
  top: 20vh;
  left: 50vw;
  border-radius: 50%;
  position: fixed;
  animation: circle 5s infinite ease-in-out;
  z-index: 0;
}

.langing-page-tag-title {
  color: var(--main-accent);
  background: var(--main-accent-gradient);
  width: fit-content;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 5px;
  z-index: 20;
}

.landing-page-title {
  margin-top: 10px;
  font-size: 64px;
  letter-spacing: 3px;
  font-weight: 800;
  z-index: 20;
  line-height: 60px;
}

.landing-page-title-span {
  color: transparent;
  -webkit-text-stroke: 1px white;
}

.scroll {
  overflow-y: scroll !important;
}
.maring-top {
  margin-top: 100px;
  height: fit-content !important;
}

.login-area {
  width: 100vw;
  overflow-y: scroll;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  box-sizing: border-box;
  background: none;
}

.login-buttons {
  display: flex;
  flex-wrap: wrap;
}

.login-button-image {
  height: 50px;
  width: 50px;
}
.spotify-image {
  height: 47px;
  width: 47px;
}

.warning-pulsing {
  height: 50px;
  width: auto;
  margin: 20px 20px 0 0;
  cursor: pointer;
}

@keyframes circle {
  from {
    transform: scale(0.97);
    left: 50vw;
  }

  50% {
    transform: scale(1);
    left: 51vw;
  }
  to {
    transform: scale(0.97);
    left: 50vw;
  }
}

@media only screen and (max-width: 1000px) {
  .landing-page-title {
    font-size: 40px;
    line-height: 40px;
  }
}

.warning-container {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  padding: 20px;
  z-index: 19999999;
  background-color: blue;
  box-sizing: border-box;
  position: absolute;
}
