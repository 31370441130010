.track {
  display: flex;
  align-items: center;
  position: relative;
  background-color: var(--main-2-trans);
  margin: 25px 25px 0 25px;
  border-radius: 15px;
  height: fit-content;
  width: 1000px;
  box-sizing: border-box;
}

.top-section p {
  margin: 5px 0;
}

.card-image {
  height: 100px;
  width: auto;
  margin-right: 10px;
  border-radius: 15px 0 0 15px;
  filter: grayscale(1);
}

.track-index {
  margin-top: 5px;
  font-size: 20px;
  color: var(--font-main-white);
}

.track-name {
  margin-right: 50px !important;
  font-size: 18px;
}

.artist-name {
  margin-right: 50px !important;
  font-size: 18px;
}

.highlight-circle {
  border-radius: 0 15px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: var(--main-1-trans);
  position: absolute;
  top: 0;
  right: 0;
}

.highlight-circle p {
  color: var(--font-main);
}

@media only screen and (max-width: 1185px) {
  .track {
    width: 80vw;
  }
}

@media only screen and (max-width: 600px) {
  .track {
    width: 350px;
  }
}
