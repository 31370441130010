.user-image-box {
  position: relative;
  width: 350px;
  height: 350px;
  background-size: cover;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-2-trans);
}

.user-image {
  position: relative;
  width: 325px;
  height: 325px;
  background-size: cover;
  border-radius: 15px;
}

.circle-box {
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  margin: 0 10px 0 0;
}

.location {
  display: flex;
}

.circle {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: limegreen;
  opacity: 0;
  top: 7.5px;
  left: 7.5px;
  animation: pulse 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
}

.c1 {
  animation-delay: -3s;
}

.c2 {
  animation-delay: -2s;
}

.c3 {
  animation-delay: -1s;
}

.c4 {
  animation-delay: 0s;
}

@keyframes pulse {
  from {
    transform: scale(0.4, 0.4);
    opacity: 0.5;
  }
  to {
    transform: scale(2, 2);
    opacity: 0;
  }
}
