.user_badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 200;
}

.user_image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 3px solid var(--font-main-white);
  box-sizing: border-box;
}

.user_image_mobile {
  height: 40px;
  width: 40px;
  box-sizing: border-box;
}

.user_information {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 5px;
}

.user_name {
  margin: 0;
  font-weight: 600;
}

.logout_button {
  margin: 0;
  cursor: pointer;
  font-size: 14px;
}

.logout_button:hover {
  color: var(--main-accent);
}

@media only screen and (max-width: 1000px) {
  .fullscreen-menu {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: var(--main-background);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    z-index: 199;
    transform: scaleY(0);
    -webkit-transition: -webkit-transform 0.4s;
    transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
    transition: transform 0.4s, -webkit-transform 0.4s;
    transform-origin: top;
  }

  .fullscreen-navigation-item {
    text-decoration: none;
    font-size: 32px;
    margin: 15px;
    font-weight: 200;
    color: var(--font-main-white);
  }

  .fullscreen-navigation-logout {
    font-size: 20px;
  }

  .fullscreen-navigation-active {
    font-weight: 600;
  }

  .menu-active {
    visibility: visible;
    z-index: 199;
    transform: scaleY(1);
  }

  .close-menu {
    height: 30px;
    width: fit-content;
    margin: 15px;
    padding: 10px;
  }
}
