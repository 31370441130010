.tracks {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tracks-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
}

.button-container {
  display: flex;
  justify-content: center;
}

.create-playlist-button {
  box-sizing: border-box;
  height: 30px;
  display: flex;
  align-self: flex-end;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  background: var(--main-2-trans);
  font-size: 16px;
  padding: 7px 10px;
  margin-right: 15px;
  margin-top: -30px;
}

.hide {
  display: none;
}

.done {
  color: var(--font-main);
  background-color: var(--spotify_main);
}

.error {
  color: var(--main-red);
  border: 2px solid var(--main-red);
  background-color: transparent;
}

.create-playlist-button:hover {
  background-color: var(--main-2);
  cursor: pointer;
  color: var(--font-main-secondary);
}

@media only screen and (max-width: 1000px) {
  .create-playlist-button {
    right: 55px;
    position: absolute;
    top: 54px;
  }
}
