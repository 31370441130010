.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.time-button.disabled {
  opacity: 0.8;
}

.time-button.disabled:hover {
  cursor: not-allowed;
  color: inherit;
  background: inherit;
}

.artist-top {
  position: relative;
  max-width: 900px;
  display: flex;
  flex-direction: row;
  background: var(--main-2-trans);
  margin: 30px 30px 60px 30px;
  border-radius: 20px;
}

.top-card-information {
  margin: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 450px;
}

.top-card-description {
  width: fit-content;
  height: fit-content;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 24px;
  font-weight: 200;
  color: var(--font-main-white);
}

.top-card-primary {
  width: 450px;
  height: fit-content;
  box-sizing: border-box;
  font-size: 48px;
  line-height: 40px;
  color: var(--font-main-white);
}

.top-card-secondary {
  width: fit-content;
  height: fit-content;
  box-sizing: border-box;
  font-size: 24px;
  line-height: 24px;
  font-weight: 200;
  margin-top: 20px;
  color: var(--font-main-white);
}

.top-card-image {
  height: 400px;
  width: 400px;
  background-size: cover;
  background-position: center;
  filter: grayscale(1);
}

.top-card-image-artist {
  border-radius: 0px 15px 15px 0px;
}

.top-card-image-track {
  border-radius: 15px 0px 0px 15px;
}

.time-switch {
  display: flex;
  flex-direction: row;
  width: 300px;
  justify-content: space-between;
  margin-top: auto;
  align-self: center;
}

.time-switch-detail {
  align-self: center;
}

.time-button {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: var(--main-2-trans);
  border-radius: 5px;
  color: var(--font-main);
  font-size: 16px;
  text-align: center;
  padding: 7px 10px;
  margin: 7px;
}

.button-selected {
  color: var(--font-main-white);
  text-shadow: black 1px 1px 5px;
  background: var(--main-accent-gradient);
}

.time-button:hover {
  color: var(--font-main-secondary);
  background: var(--main-2);
  cursor: pointer;
}

.padding-right {
  padding-right: 50px;
}

.padding-left {
  padding-left: 50px;
}

@media only screen and (max-width: 1000px) {
  .artist-top {
    flex-direction: column;
    align-items: center;
    margin: 25px 25px 0 25px;
    max-width: 350px;
  }

  .top-card-primary {
    width: fit-content;
    height: fit-content;
    margin-bottom: 40px;
    overflow-wrap: anywhere;
  }

  .top-card-information {
    min-height: 250px;
    align-items: center;
    max-width: 300px;
    margin: 25px;
  }

  .top-card-image {
    height: 325px;
    width: 350px;
  }

  .time-switch {
    width: 250px;
  }

  .top-card-information-artist {
    align-items: center;
    margin-bottom: 50px;
  }

  .top-card-information-track {
    align-items: center;
    margin-top: 50px;
  }

  .top-card-image-artist {
    border-radius: 0px 0px 15px 15px;
  }

  .top-card-image-track {
    border-radius: 15px 15px 0px 0px;
  }

  .padding-right {
    padding-right: 0px;
  }

  .padding-left {
    padding-left: 0px;
  }
}
